import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/billing/generate/customers/${payload.consumerType}`)
          .then(({ data: { results } }) => resolve(results))
          .catch(error => reject(error))
      })
    },
    generateBills(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/billing/generate/${payload.selectionMode}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
