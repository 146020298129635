<template>
  <div>
    <form-wizard
      color="#29c76f"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Generate"
      back-button-text="Previous"
      class="wizard-vertical mb-3 billing-wizard"
      @on-complete="generateBills"
    >
      <!-- Customers -->
      <tab-content
        title="Customers"
        :before-change="validateCustomers"
      >
        <validation-observer
          ref="customerRules"
          tag="form"
        >
          <b-row>
            <!-- mode selection -->
            <b-col md="6">
              <b-form-group label-for="selection-mode">
                <h5 class="mb-[0.43rem]">
                  Selection Mode
                </h5>
                <validation-provider
                  #default="{ errors }"
                  name="mode"
                  rules="required"
                >
                  <div class="inline-flex gap-4">
                    <b-form-radio
                      v-model="reportFilters.selectionMode"
                      name="selection-mode"
                      value="all"
                      class="custom-control-primary"
                      @input="selectionModeChanged"
                    >
                      All Customers
                    </b-form-radio>
                    <b-form-radio
                      v-model="reportFilters.selectionMode"
                      name="selection-mode"
                      value="custom"
                      class="custom-control-primary"
                      @input="selectionModeChanged"
                    >
                      Custom Selection
                    </b-form-radio>
                  </div>
                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="month">
                <h5 class="mb-0">
                  Billing Month
                </h5>
                <validation-provider
                  #default="{ errors }"
                  name="Billing Month"
                  rules="required"
                >
                  <div class="inline-flex gap-4">
                    <month-picker
                      :max-date="getMaxMonth()"
                      @input="monthPicked"
                    />
                    <b-form-input
                      v-model="reportFilters.billingMonth"
                      name="billing-month"
                      class="hidden"
                    />
                  </div>
                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- consumer type -->
            <b-col
              v-if="reportFilters.selectionMode == 'custom'"
              md="12"
            >
              <b-form-group
                label="Consumer Type"
                label-for="consumer_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Consumer Type"
                  rules="required"
                >
                  <v-select
                    v-model="reportFilters.consumerType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Consumer Type"
                    :options="filterConsumerTypeOptions"
                    class="w-100"
                    @input="consumerTypeChanged"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- customers -->
            <b-col
              v-if="
                reportFilters.selectionMode == 'custom' &&
                  reportFilters.consumerType
              "
              md="12"
            >
              <b-form-group
                label="Customers"
                label-for="customers"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customers"
                  rules="required"
                >
                  <v-select
                    v-model="reportFilters.customers"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Customers"
                    multiple
                    label="reference_no"
                    :options="filterCustomerOptions"
                    class="w-100"
                  >
                    <template v-slot:option="option">
                      <span :class="option.icon" />
                      {{ option.reference_no }} | {{ option.consumer_name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>

    <busy-modal :card="busyCard" />
  </div>
</template>

<script>
import MonthPicker from '@leecom/date-picker/MonthPicker.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormRadio,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

import { showErrorToast } from '@leecom/utils/support'
import BusyModal from '@/@leecom/busy-modal/BusyModal.vue'

moment.locale('en')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    MonthPicker,
    flatPickr,
    BusyModal,
  },
  data() {
    return {
      STORE_MODULE_NAME: 'app-billing-generate',
      reportFilters: {
        consumerType: null,
        selectionMode: null,
        customers: null,
        billingMonth: null,
      },
      filters: {
        billingMonth: null,
      },

      filterCustomerOptions: [],

      required,

      busyCard: {
        state: null,
      },
    }
  },
  computed: {
    filterConsumerTypeOptions() {
      const options = this.$store.state.app.enums
        ? _.cloneDeep(this.$store.state.app.enums.ConsumerType)
        : []

      if (_.find(options, { key: 'ALL' }) == undefined) {
        options.unshift({
          key: 'ALL',
          label: 'All',
          value: 'all',
        })
      }

      return options
    },
  },
  methods: {
    getMaxMonth() {
      if (moment().format('DD') >= 2) {
        return new Date(moment().subtract(1, 'month'))
      }
      return new Date(moment().subtract(2, 'month'))
    },
    consumerTypeChanged(option) {
      this.filterCustomerOptions = []
      this.reportFilters.customers = null

      this.filters.consumerType = option ? option.value : null
      if (option) {
        this.reloadCustomers()
      }
    },
    selectionModeChanged() {
      this.filterCustomerOptions = []
      this.reportFilters.consumerType = null
      this.reportFilters.customers = null
    },
    reloadCustomers() {
      this.$store
        .dispatch(`${this.STORE_MODULE_NAME}/fetchCustomers`, {
          consumerType: this.filters.consumerType,
        })
        .then(results => {
          this.filterCustomerOptions = results
        })
    },
    monthPicked(month) {
      this.reportFilters.billingMonth = month.format('YYYY-MM-DD')
      this.filters.billingMonth = month
    },
    validateCustomers() {
      return new Promise((resolve, reject) => {
        this.$refs.customerRules.validate().then(success => {
          console.log(success)
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    generateBills() {
      const { billingMonth } = this.filters
      const payload = {
        startTimestamp: moment
          .tz(
            billingMonth.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            'UTC',
          )
          .valueOf(),
        endTimestamp: moment
          .tz(billingMonth.endOf('month').format('YYYY-MM-DD HH:mm:ss'), 'UTC')
          .valueOf(),
        customers: _.map(this.reportFilters.customers, 'id'),
        selectionMode: this.reportFilters.selectionMode,
      }

      this.busyCard.state = 'WAITING'

      this.$store
        .dispatch(`${this.STORE_MODULE_NAME}/generateBills`, payload)
        .then(() => {
          this.busyCard.state = null

          const message = 'Task Complete.'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
          const { response } = error
          this.busyCard.state = null
          showErrorToast(this.$toast, response)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after,
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before {
  content: '';
}

.vue-form-wizard {
  box-shadow: none;
}

.billing-wizard .wizard-nav {
  display: none;
}
</style>
