<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <h5 class="mb-[0.43rem]">
          Generate Bills
        </h5>
      </b-card-header>
      <b-card-body>
        <bill-generate-wizard />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import generateStoreModule from './generateStoreModule'
import BillGenerateWizard from './BillGenerateWizard.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    vSelect,
    BillGenerateWizard,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-billing-generate'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, generateStoreModule, {
        preserveState: false,
      })
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const consumerType = ref(null)

    const consumerTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.ConsumerType)
      : []))

    return {
      consumerType,
      consumerTypeOptions,
    }
  },
}
</script>
